@media only screen and (max-width: 1800px) {
  .page {
    max-width: 780px;

    #toc-auto {
      margin-left: 800px;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .page {
    max-width: 680px;

    #toc-auto {
      margin-left: 700px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .page {
    max-width: 560px;

    #toc-auto {
      margin-left: 580px;
    }
  }
}

@media only screen and (max-width: 960px) {
  #header-desktop {
    .header-wrapper {
      margin: 0 1rem;
    }
  }

  .page {
    max-width: 80%;

    #toc-auto {
      display: none;
    }

    #toc-static {
      display: block;
    }
  }
}

@media only screen and (max-width: 560px) {
  #header-desktop {
    display: none;
  }

  #header-mobile {
    display: block;
  }

  .page {
    max-width: 100%;
    padding-top: $page-padding-top-mobile;

    .categories-card {
      .card-item {
        width: 100%;
      }
    }
  }

  .copyright {
    .copyright-line {
      .icp-splitter {
        display: none;
      }
      .icp-br {
        display: block;
      }
    }
  }
}
